.MuiPickersBasePicker-pickerView {
    border: 1px solid #E7E7ED;
    border-radius: 10px;
    min-height: 200px !important;
    min-width: 300px !important;
}

.MuiPickersCalendar-transitionContainer {
    min-height: 188px !important;
}

.MuiPickersDay-dayDisabled {
    color: tomato;
}

.MuiPickersDay-day.MuiPickersDay-dayDisabled {
    color: #E7E7ED;
}

button.MuiPickersDay-day {
    color: #6B6C7E;
}

button.MuiPickersDay-current {
    color: #F9B233;
}

button.MuiPickersDay-daySelected {
    color: #FFFFFF;
}

.MuiPickersDay-day > span > p, .MuiPickersDay-day > span {
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
}

p.MuiTypography-body2 {
    font-size: 12px;
    font-weight: 600;
}


.MuiPickersDay-daySelected {
    background-color: #F9B233 !important;
}