.row-services-calendar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
}

.services-schedule {
  display: inline-flex;
  width: 80%;
  min-height: 27px;
}

.services-schedule span {
  margin-right: 55px;
  padding: 0 15px;
  cursor: pointer;
  color: #6b6c7e;
  font-weight: 600;
}

.services-schedule .service-active {
  border-bottom: 2px solid #fec65a;
  color: #272833;
  transition: 0.1s;
}

.schedule-calendar {
  display: inline-flex;
  width: 20%;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.14rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.schedule-calendar .datepicker {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  height: 40px;
  align-items: center;
  margin-left: 10px;
  width: 173px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  color: #6b6c7e;
}

.schedules {
  margin-top: 30px;
}

.schedules .schedule-titles {
  color: #6b6c7e;
  font-weight: normal;
  border-bottom: 1px solid #e5e5e5;
}

.schedules .schedule-titles th {
  padding: 0px;
  text-align: left;
}

.schedules .schedule-titles th:first-child {
  padding-left: 20px;
}

.schedules .schedule-titles .title-status {
  display: flex;
  justify-content: flex-end;
  margin: 0 40px;
}

.schedules .schedule-titles .title-status span:nth-child(1) {
  padding-right: 110px;
}

.schedules .schedule-info {
  background-color: #fff;
  border: 1px solid #e5e5e5;
}

.schedules .schedule-info td {
  height: 74px;
  border-bottom: 1px solid #e5e5e5;
}

.schedules .schedule-info td:first-child {
  padding: 0 20px 0 23px;
}

.schedule-info .hour {
  display: flex;
  flex-direction: column;
}

.schedule-info .hour .start-hour {
  font-weight: bold;
}

.schedule-info .hour .finish-hour {
  font-size: 0.71rem;
  color: #a7a9bc;
}

.schedule-info .person {
  display: flex;
  flex-wrap: nowrap;
  width: 80%;
}

.schedule-info .person .person-image {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 1px solid #a7a9bc;
  flex-wrap: nowrap;
}

.schedule-info .person .person-info {
  display: inline-flex;
  flex-direction: column;
  margin-left: 10px;
  flex-wrap: nowrap;
}

.schedule-info .person .person-info .person-document {
  color: #6b6c7e;
}

.schedule-info .schedule-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 189px;
  height: 25px;
  border-radius: 12.5px;
  text-transform: uppercase;
  font-size: 0.71rem;
}

.schedule-info .schedule-status.await-patient {
  background-color: #f7f8f9;
  color: #6b6c7e;
}

.schedule-info .schedule-status.validated-token {
  background-color: #eef2fa;
  color: #2e5aac;
}

.schedule-info .schedule-status.in-attendance {
  background-color: #fff4ec;
  color: #b95000;
}

.schedule-info .schedule-status.pending-result {
  background-color: #fff8de;
  color: #df9000;
}

.schedule-info .schedule-status.send-result {
  background-color: #d5f2bf;
  color: #287d3c;
}

.schedule-info .schedule-status.not-attend {
  background-color: #feefef;
  color: #da1414;
}

.schedule-info .schedule-status.proceeding-perfomed {
  background-color: #edf9f0;
  color: #287d3c;
}

.schedule-info td:last-child {
  width: 100px;
  padding-left: 40px;
}

@media (max-width: 1000px) {
  .row-services-calendar {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    height: auto;
  }

  .services-schedule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .services-schedule span {
    margin-bottom: 10px;
    padding: 0;
  }

  .services-schedule,
  .schedule-calendar {
    width: auto;
  }

  .schedules .schedule-titles th:first-child {
    padding-left: 0px;
  }

  .schedules .schedule-info td:nth-child(n + 2) {
    min-width: 300px;
  }

  .schedule-info td:last-child {
    display: table-cell;
  }

  .schedules {
    overflow-x: scroll;
  }
  .schedules::-webkit-scrollbar-thumb:horizontal {
    border-radius: 5px;
    height: 2px;
    background-color: #fec65a;
  }

  .schedules::-webkit-scrollbar {
    height: 6px;
    background-color: #f4f4f4;
  }

  .schedules::-webkit-scrollbar-track-piece {
    background: #dad7d7;
  }
}
